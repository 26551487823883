"use client"; // browser only: https://react.dev/reference/react/use-client
import { HoneycombWebSDK } from "@honeycombio/opentelemetry-web";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";

const HONEYCOMB_API_KEY = import.meta.env.VITE_HONEYCOMB_API_KEY;
const HONEYCOMB_SERVICE_NAME = import.meta.env.VITE_HONEYCOMB_SERVICE_NAME;
const configDefaults = {
  ignoreNetworkEvents: false,
  // Regex to match your backend URLs. Update to the domains you wish to include.
  propagateTraceHeaderCorsUrls: [
    /^https:\/\/(dev\.server\.|dev\.|devconnective\.|connective\.)api\.financeable\.com\.au/g,
  ],
};
const Observability = () => {
  try {
    const sdk = new HoneycombWebSDK({
      debug: import.meta.env.DEV,
      apiKey: HONEYCOMB_API_KEY,
      serviceName: HONEYCOMB_SERVICE_NAME,
      instrumentations: [
        getWebAutoInstrumentations({
          // Loads custom configuration for xml-http-request instrumentation.
          "@opentelemetry/instrumentation-xml-http-request": configDefaults,
          "@opentelemetry/instrumentation-fetch": configDefaults,
          "@opentelemetry/instrumentation-document-load": configDefaults,
        }),
      ],
    });
    sdk.start();
  } catch (e) {
    return null;
  }
  return null;
};

export { Observability };
