import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { reusableFetch } from "../../utils/useAxios";

export const getCustomerById = createAsyncThunk("getCustomerById/get", async ({ customerId }, { rejectWithValue }) => {
  const customer = await reusableFetch(`customer/${customerId}`, "GET", rejectWithValue);
  return customer;
});

const defaultState = {
  contactData: null,
  isCustomerLogin: false,
}

export const customerSlice = createSlice({
  name: "customer",
  initialState: defaultState,
  reducers: {
    saveValue: (state, action) => _.merge(state, action.payload),
  },
  extraReducers: {
    [getCustomerById.fulfilled]: (state, { payload }) => {
      state.contactData = payload.data.data
    },
  },
});

export const { saveValue } = customerSlice.actions;

export const customerSelector = (state) => state.customer;
