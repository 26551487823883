import type { PostHogConfig } from "posthog-js";
import { fallbackFeatureFlags } from "../utils/featureFlags";

const PostHogOptions: Partial<PostHogConfig> = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  bootstrap: {
    featureFlags: fallbackFeatureFlags,
  },
};

export { PostHogOptions };
